
.container {
    max-width: 1200px;
    width: calc(100% - 120px);
    margin: auto;
}

.Heading-innner {
    padding: 4rem 5rem;
    background: #F2F5FF;
    height: fit-content;
    border-radius: 30px;
    transition-duration: .5s;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.Heading-innner .heading {
    font-family: Livvic;
}

.Heading-innner .heading-title {
    color: #181b2e;
    font-weight: 700;
    line-height: 3.75rem;
    font-size: 3.25rem;
}

.Heading-innner .heading-title-sm {
    margin-top: 1.5rem;
    line-height: 1.625rem;
    font-size: 1.125rem;
}

.container .content{
    font-family: Manrope;
    display: flex;
    flex-direction: column;
    gap: 60px;
}
.content h3{
    font-weight: 600;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.content-section h2{
    line-height: 1.1875rem;
    font-size: 1.325rem;
    margin-bottom: 1rem;
    color: #ED628B;
    font-weight: 900;
}
.content-section a{
    color: #1f57ff;
    font-weight: 600;
    text-decoration: none;
}
.content-section ul{
    padding-left: 20px;
}
@media screen and (max-width:567px) {
    .container{
        width: calc(100% - 50px);
    }
    .content{
        padding: 0 10px;
    }
    .Heading-innner{
        padding: 1.5rem;
    }
    .Heading-innner .heading-title{
        line-height: 2.15rem;
        font-size: 1.55rem;
    }
}